import { Main } from '../components/main/main';
import { Rainbowkit } from '../components/rainbowkit/rainbowkit';
import { ThemeProvider } from '../components/theme-provider/theme-provider';

export const App: React.FC = () => (
  <ThemeProvider>
    <Main>
      <Rainbowkit />
    </Main>
  </ThemeProvider>
);
