export type GetNonceResult = GetNonceFailResult | GetNonceSuccessResult;

interface GetNonceFailResult {
  success: false;
  error: unknown;
}

interface GetNonceSuccessResult {
  success: true;
  nonce: string;
}

export interface GetNonceRequestData {
  publicAddress: string;
}
