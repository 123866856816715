// This file can be replaced during build by using the `fileReplacements` array.
// When building for production, this file is replaced with `environment.prod.ts`.

export const environment: Environment = {
  production: false,
  webUrl: 'http://localhost:3000',
  firebaseApiKey: 'AIzaSyAYw6cgx8hlMzwVlUWVHRLO6y2_zxrNM7g',
  firebaseAppId: '1:463182731954:web:8a5eca08bfb04822c2c7ab',
  firebaseAauthDomain: 'dex-screener-development.firebaseapp.com',
  firebaseMessagingSenderId: '463182731954',
  firebaseProjectId: 'dex-screener-development',
  firebaseStorageBucket: 'dex-screener-development.appspot.com',
  firebaseMeasuramentId: 'G-GY1SLYLR7S',
};
