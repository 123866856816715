import { SiweMessage } from 'siwe';

export type VerifyMessageResult = VerifyMessageFailResult | VerifyMessageSuccessResult;

export interface VerifyMessageFailResult {
  success: false;
  error: unknown;
}

export interface VerifyMessageSuccessResult {
  success: true;
  authToken: string;
}

export interface VerifyMessageRequestData {
  message: SiweMessage;
  signature: string;
}
