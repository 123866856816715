import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { DefaultTheme, ThemeProvider as StyledThemeProvider } from 'styled-components';

export interface ThemeProviderProps {
  readonly children?: React.ReactNode;
}

const darkTheme: DefaultTheme = {
  background: '#1a1b1f',
  text: '#aaa',
};

const lightTheme: DefaultTheme = {
  background: '#fff',
  text: '#555',
};

type ColorMode = 'light' | 'dark';

export const useColorScheme = (): ColorMode => {
  const [searchParams] = useSearchParams();

  return getColorMode(searchParams.get('colorMode'));
};

export const useColorModeValue = <Light, Dark>(light: Light, dark: Dark): Light | Dark => {
  const colorScheme = useColorScheme();

  return colorScheme === 'light' ? light : dark;
};

export const ThemeProvider: React.FC<ThemeProviderProps> = ({ children }) => {
  const [searchParams] = useSearchParams();

  const colorMode = getColorMode(searchParams.get('colorMode'));

  return <StyledThemeProvider theme={colorMode === 'light' ? lightTheme : darkTheme}>{children}</StyledThemeProvider>;
};

function getColorMode(colorMode: unknown): ColorMode {
  if (colorMode === 'light' || colorMode === 'dark') return colorMode;

  return 'light';
}
