import { connectorsForWallets } from '@rainbow-me/rainbowkit';
import { injectedWallet, metaMaskWallet, rainbowWallet, walletConnectWallet } from '@rainbow-me/rainbowkit/wallets';
import React from 'react';
import { WagmiConfig, chain, configureChains, createClient } from 'wagmi';
import { publicProvider } from 'wagmi/providers/public';

export interface NavLoginProviderProps {
  readonly children: React.ReactNode;
}

export const wagmiChainsConfig = configureChains([chain.mainnet], [publicProvider()]);

export const wagmiConnectors = connectorsForWallets([
  {
    groupName: 'DEX Screener',
    wallets: [
      metaMaskWallet({ chains: [] }),
      injectedWallet({ chains: [] }),
      rainbowWallet({ chains: [] }),
      walletConnectWallet({ chains: [] }),
    ],
  },
]);

export const wagmiClient = createClient({
  autoConnect: true,
  connectors: wagmiConnectors,
  provider: wagmiChainsConfig.provider,
});

export const WagmiProvider: React.FC<NavLoginProviderProps> = ({ children }) => <WagmiConfig client={wagmiClient}>{children}</WagmiConfig>;
