import { createAuthenticationAdapter as createAdapter } from '@rainbow-me/rainbowkit';
import { SiweMessage } from 'siwe';

export interface AuthenticationAdapter {
  getNonce: (publicAddress?: string) => Promise<string>;
  createMessage: (args: { nonce: string; address: string; chainId: number }) => SiweMessage;
  verify: (args: { publicAddress?: string; message: SiweMessage; signature: string }) => Promise<boolean>;
}

export const createAuthenticationAdapter = (params: {
  publicAddress?: string;
  adapter: AuthenticationAdapter;
}): ReturnType<typeof createAdapter<SiweMessage>> => ({
  getNonce: () => params.adapter.getNonce(params.publicAddress),
  createMessage: (args) => params.adapter.createMessage(args),
  getMessageBody: ({ message }) => message.toMessage(),
  verify: (args) => params.adapter.verify({ ...args, publicAddress: params.publicAddress }),
  signOut: async () => {},
});
