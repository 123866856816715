import { SiweMessage } from 'siwe';

export interface SuccessMessagePayload {
  type: 'verifyMessage';
  success: true;
  publicAddress: string;
  signature: string;
  message: SiweMessage;
}

export interface FailMessagePayload {
  type: 'verifyMessage';
  success: false;
  publicAddress: string;
  error: unknown;
}

export interface CloseModalPayload {
  type: 'closeModal';
}

export type MessagePayload = FailMessagePayload | SuccessMessagePayload | CloseModalPayload;
