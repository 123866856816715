import {
  AuthenticationStatus,
  RainbowKitAuthenticationProvider,
  RainbowKitProvider,
  darkTheme,
  lightTheme,
  useConnectModal,
} from '@rainbow-me/rainbowkit';
import rainbowkitStylesheet from '@rainbow-me/rainbowkit/styles.css';
import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { useAccount } from 'wagmi';

import { AuthenticationAdapter, createAuthenticationAdapter } from '../authentication-adapter/authentication-adapter';
import { WagmiProvider } from '../wagimi-provider/wagmi-provider';

import rainbowkitOverrides from './rainbowkit-overrides.css';

export const useRainbowkitLoginModal = (): { openRainbowkitLoginModal: () => void } => {
  const { openConnectModal } = useConnectModal();

  return {
    openRainbowkitLoginModal: () => {
      if (openConnectModal) openConnectModal();
    },
  };
};

const ConnectModal: React.FC = () => {
  const { openRainbowkitLoginModal } = useRainbowkitLoginModal();
  useEffect(() => openRainbowkitLoginModal(), []);

  return null;
};

interface RainbowkitLoginProps {
  readonly children?: ReactElement;
  readonly opened: boolean;
  readonly authenticationAdapter: AuthenticationAdapter;
  readonly colorMode?: 'light' | 'dark';
  readonly includeStyleOverrides?: boolean;
}

export const RainbowkitProvider: React.FC<RainbowkitLoginProps> = (props) => {
  const { address } = useAccount();
  const [loginStatus] = useState<AuthenticationStatus>('unauthenticated');

  const theme = props.colorMode === 'light' ? lightTheme() : darkTheme();
  const adapter = useMemo(() => createAuthenticationAdapter({ publicAddress: address, adapter: props.authenticationAdapter }), [address]);

  return (
    <WagmiProvider>
      <link href={rainbowkitStylesheet} rel={'stylesheet'} />
      {props.includeStyleOverrides && <link href={rainbowkitOverrides} rel={'stylesheet'} />}

      <RainbowKitAuthenticationProvider adapter={adapter} status={loginStatus}>
        <RainbowKitProvider modalSize="compact" chains={[]} theme={theme}>
          {props.opened && <ConnectModal />}
          {props.children && props.children}
        </RainbowKitProvider>
      </RainbowKitAuthenticationProvider>
    </WagmiProvider>
  );
};
