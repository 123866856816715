import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';

export interface MainProps {
  readonly children?: React.ReactNode;
}

const Container = styled.div`
  position: fixed;
  align-items: center;
  justify-content: center;
  overflow: auto;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  background-color: ${(props) => props.theme.background};
`;

const InnerContainer = styled.div`
  margin: 0.75rem;
`;

export const Main: React.FC<PropsWithChildren> = ({ children }) => (
  <Container>
    <InnerContainer>{children}</InnerContainer>
  </Container>
);
