import {
  AuthenticationAdapter,
  CloseModalPayload,
  FailMessagePayload,
  GetNonceRequestData,
  GetNonceResult,
  RainbowkitProvider,
  SuccessMessagePayload,
} from '@dexscreener/feature-auth';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { SiweMessage } from 'siwe';

import { environment } from '../../../data-sources/environment.data-source';
import { firebaseApp } from '../../../data-sources/firebase.data-source';
import { useColorScheme } from '../theme-provider/theme-provider';

const functions = getFunctions(firebaseApp);
const addEventListenerToCloseButton = (): void => {
  const calcelButtonSelector = 'button[data-testid="rk-auth-message-button"] ~ button';
  const cancelButton = document.querySelector(calcelButtonSelector);

  cancelButton?.addEventListener('click', () => {
    const responsePayload: CloseModalPayload = { type: 'closeModal' };
    window.ReactNativeWebView.postMessage(JSON.stringify(responsePayload));
  }, { once: true });
};

const authenticationAdapter: AuthenticationAdapter = {
  getNonce: async (publicAddress) => {
    if (!publicAddress) return '';

    addEventListenerToCloseButton();
    const getNonce = httpsCallable<GetNonceRequestData, GetNonceResult>(functions, 'getNonce');
    const result = await getNonce({ publicAddress });

    if (!result.data.success) throw Error('Failed on getting the nonce.');

    return result.data.nonce;
  },

  createMessage: ({ nonce, address, chainId }) => {
    const message = new SiweMessage({
      domain: environment.webUrl,
      address,
      statement: 'DEX Screener.',
      uri: environment.webUrl,
      version: '1',
      nonce,
      chainId,
    });

    return message;
  },

  verify: async ({ publicAddress, message, signature }) => {
    if (!publicAddress) return Boolean(false);

    try {
      const responsePayload: SuccessMessagePayload = {
        type: 'verifyMessage',
        success: true,
        publicAddress,
        signature,
        message,
      };
      window.ReactNativeWebView.postMessage(JSON.stringify(responsePayload));

      return true;
    } catch (e) {
      const responsePayload: FailMessagePayload = {
        type: 'verifyMessage',
        success: false,
        publicAddress,
        error: e,
      };
      window.ReactNativeWebView.postMessage(JSON.stringify(responsePayload));

      return false;
    }
  },
};

export const Rainbowkit: React.FC = () => {
  const colorMode = useColorScheme();

  return <RainbowkitProvider authenticationAdapter={authenticationAdapter} colorMode={colorMode} includeStyleOverrides={true} opened={true} />;
};
