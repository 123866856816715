import { FirebaseApp, initializeApp } from 'firebase/app';

import { environment } from './environment.data-source';

export const firebaseApp: FirebaseApp = (
  initializeApp({
    apiKey: environment.firebaseApiKey,
    appId: environment.firebaseAppId,
    authDomain: environment.firebaseAauthDomain,
    messagingSenderId: environment.firebaseMessagingSenderId,
    projectId: environment.firebaseProjectId,
    storageBucket: environment.firebaseStorageBucket,
    measurementId: environment.firebaseMeasuramentId,
  })
);
